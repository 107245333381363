import React from "react"
import "url-search-params-polyfill"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import { search } from "../api/handlers"
import BlogPostsList from "../components/BlogPostsList"
import SearchTitle from "../components/common/SearchTitle"
import LoadMoreContainer from "../components/common/LoadMoreContainer"
import Spinner from "../components/common/Spinner"

const SecondPage = () => {
  const query = React.useMemo(() => {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search)
      return searchParams.get("query").trim()
    }

    return ""
  })

  const [searchResults, setSearchResults] = React.useState([])
  const [isSearchInProgress, setSearchInProgress] = React.useState(true)

  React.useEffect(() => {
    if (!query) {
      navigate("/")
      return
    }

    async function getSearchResults() {
      try {
        const articles = await search(query)
        setSearchResults(articles)
      } catch (e) { }
      setSearchInProgress(false)
    }

    getSearchResults()
  }, [query])

  return query ?
    (
      <Layout>
        <SearchTitle>
          <h1>Search results for "{query}"</h1>
          {!isSearchInProgress && (
            <p>
              {searchResults.length === 0
                ? "No results matching your criteria."
                : `${searchResults.length} result${searchResults.length > 1 ? "s" : ""} found.`
              }
            </p>
          )}
        </SearchTitle>
        {isSearchInProgress
          ? (
            <LoadMoreContainer>
              <Spinner />
            </LoadMoreContainer>
          )
          : (
            <BlogPostsList
              className="search-results"
              posts={searchResults}
            />
          )
        }
      </Layout>
    )
    : null
}

export default SecondPage

import styled from "styled-components"

import * as Colors from "../../atomics/colors"

const SearchTitle = styled.div`
  font-family: "Ubuntu", sans-serif;
  color: ${Colors.darkGrey};
  display: inline-block;
  margin: 0 0 24px;

  > h1 {
    font-family: "Ubuntu", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.33;
    margin-bottom: 8px;
  }

  p {
    font-family: "Open Sans", sans-serif;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.5;
  }
`

export default SearchTitle